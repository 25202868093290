

// download pdf mixins
export default {
  data() {
    return {
      isDownloading: false,
    };
  },
  methods: {
    async handlePDFDownload(entityHypnID, uri, type, send) {
      const payload =
        send === undefined
          ? entityHypnID
          : {
              id: entityHypnID,
              send: false,
            };

      try {
        this.isDownloading = true;
        const res = await this.$store.dispatch(uri, payload);

        if (process.env.NODE_ENV === "development") {
          console.log(res);
        }

        this.showToast({
          sclass: "success",
          show: true,
          message: res.message || type + " generated successfully",
          timeout: 3000,
        });

        this.isDownloading = false;
        console.log("we dey here: ", this.isDownloading);
        // open a new window tab with res.data.file as the url
        window.open(res.data.file, "_blank");
      } catch (error) {
        console.error(error);
        this.showToast({
          sclass: "error",
          show: true,
          message: error.msg || "Error Generating " + type,
          timeout: 3000,
        });
      } finally {
        this.isDownloading = false;
      }
    },
  },
};
