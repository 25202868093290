<template>
  <v-container>
    <v-row>
      <v-col class="py-1">
        <div class="d-flex mx-md-6 mt-4 mt-md-6">
          <p class="page__title">
            Quotation <v-icon color="#8f96a1">mdi-chevron-right</v-icon>
            <span style="color: #8f96a1">preview</span>
          </p>
          <v-spacer></v-spacer>
          <v-btn
            @click="$router.go(-1)"
            text
            rounded
            large
            v-if="$vuetify.breakpoint.mdAndUp"
          >
            <v-icon left>mdi-chevron-left</v-icon> Back
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row class="my-md-6">
      <v-col cols="12" class="py-1">
        <div class="pContainer">
          <div
            class="d-flex justify-center align-center"
            style="height: 100vh; width: 100%"
            v-if="loadingPage"
          >
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
          <div
            class="d-flex justify-center align-center flex-column px-md-10"
            style="width: 70%"
            v-else
          >
            <div
              class="d-flex align-center justify-end actionTxt py-1"
              style="width: 100%"
            >
              <v-btn
                x-small
                text
                rounded
                class="d-flex justify-center align-center pa-2"
                @click="
                  $router.push({
                    path: `/quote/edit/${$route.params.id}`,
                    query: { edit: true },
                  })
                "
              >
                <img
                  :src="require('@/assets/icons/pencilIcon.svg')"
                  alt="edit-doc"
                />
                <span class="headerBtnTxt px-1">edit</span>
              </v-btn>
              <v-btn
                x-small
                text
                rounded
                class="d-flex justify-center align-center pa-2"
                @click="
                  $router.push({
                    path: `/quote/edit/${$route.params.id}`,
                    query: { delivery_note: true },
                  })
                "
              >
                <img
                  :src="require('@/assets/icons/pencilIcon.svg')"
                  alt="delivery-note"
                />
                <span class="headerBtnTxt px-1">Delivery Note</span>
              </v-btn>
              <v-btn
                @click="makeInvoice"
                x-small
                text
                rounded
                class="d-flex justify-center align-center pa-2"
              >
                <v-progress-circular
                  indeterminate
                  color="primary"
                  :size="10"
                  :width="2"
                  v-if="sendingQuote"
                ></v-progress-circular>
                <img
                  :src="require('@/assets/icons/Vector.svg')"
                  alt="clone-doc"
                  v-else
                  class="font-weight-bold"
                />
                <span class="headerBtnTxt px-1">Make Invoice</span>
              </v-btn>
              <v-btn
                x-small
                text
                rounded
                class="d-flex justify-center align-center pa-2"
                @click="triggerDownload"
              >
                <img
                  :src="require('@/assets/icons/download-quote.svg')"
                  alt="clone-doc"
                />
                <span class="headerBtnTxt px-1">download</span>
              </v-btn>
              <!-- <v-btn x-small text rounded class="d-flex justify-center align-center pa-2">
                <img :src="require('@/assets/icons/slider-horizontal.svg')" alt="clone-doc" />
                <span class="headerBtnTxt px-1">preview</span>
              </v-btn> -->
            </div>
            <div class="pContainer__card pa-4 pa-md-8 mb-6">
              <QuoteTemplate
                ref="QuoteTemplateRef"
                :documentTitle="quoteDetails.label"
                :date="quoteDetails.issued"
                :lines="lineItems"
                :quotenumber="quoteDetails.rfq"
                :client_custom1="quoteDetails.attention"
                :currencySym="quoteDetails.currency"
                :total="quoteDetails.subtotal"
                :company_to="companydetails.company_name"
                :address_to="companydetails.address || ''"
                :zip_to="companydetails.postcode || ''"
                :city_to="companydetails.city || ''"
                :country_to="companydetails.country || ''"
                :company_from="$orgDetails.company.company_name || ''"
                :organizationLogo="$orgDetails.logo"
                :address_from="$orgDetails.office[0].address || ''"
                :zip_from="$orgDetails.office[0].postcode || ''"
                :country_from="$orgDetails.office[0].country || ''"
                :priceValidity="quoteDetails.pricevalidity || ''"
                :deliveryterms="quoteDetails.delivery_terms || ''"
                :showTax="quoteDetails.taxed"
                :otherTerms="quoteDetails.other_terms"
              />
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import QuoteTemplate from "./components/QuoteTemplate.vue";
import downloadPdfMixin from "@/mixins/downloadPdfMixin";
export default {
  name: "previewQuote",
  components: {
    QuoteTemplate,
  },
  data() {
    return {
      loadingPage: false,
      sendingQuote: false,
    };
  },
  mixins: [downloadPdfMixin],
  computed: {
    ...mapGetters({
      quoteDetails: "quotes/getSingleQuote",
      customer: "contacts/getCustomer",
    }),

    // map line items to array
    lineItems() {
      return this.quoteDetails.lines.map((line) => {
        return {
          addon: line.addon,
          description: line.description,
          quantity: line.quantity,
          UOM: line.uom,
          price: line.price.unit,
          total: line.price.total,
          material_no: line.material_no,
        };
      });
    },

    companydetails() {
      return {
        company_name: this.customer?.data?.company?.company_name ?? "",
        address: this.customer?.data?.address?.address ?? "",
        postcode: this.customer?.data?.address?.postcode ?? "",
        city: this.customer?.data?.address?.city ?? "",
        country: this.customer?.data?.address?.country ?? "",
      };
    },
  },
  methods: {
    // mapActions for toast
    ...mapActions({ showToast: "ui/showToast" }),

    triggerDownload() {
      console.log(this.$orgHypnId);
      const uri = "quotes/triggerQuoteDownload";
      const type = "Quote";

      // from mixins
      this.handlePDFDownload(this.$orgHypnId, uri, type);
    },

    async getQuote(id) {
      this.loadingPage = true;
      let payload = {
        organization_hypnID: this.$orgHypnId,
        quoteHypnId: id,
      };

      try {
        this.loadingPercent = 50;
        const { data } = await this.$store.dispatch("quotes/getQuote", payload);
        console.log(data.data.label);
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingPage = false;
      }
    },

    async getCustomerDetails() {
      const id = this.quoteDetails?.counterparty;

      await this.$store.dispatch("contacts/getSingleCustomer", id);
    },

    //send quote
    async makeInvoice() {
      this.sendingQuote = true;
      try {
        const payload = {
          quoteHypnId: this.$route.params.id,
          organization_hypnID: this.$orgHypnId,
        };

        const { data } = await this.$store.dispatch(
          "quotes/convertQuoteToInvoice",
          payload
        );

        // console.log only in dev env

        if (process.env.NODE_ENV === "development") {
          console.log(data.id);
        }

        if (data.status === "success") {
          this.showToast({
            sclass: "success",
            show: true,
            message: "Quote converted successfully",
            timeout: 3000,
          });
        }

        this.$router.push(`/inbox/${data.id}`);
      } catch (error) {
        console.error(error);

        this.showToast({
          sclass: "error",
          show: true,
          message: error.message || "An error occurred",
          timeout: 3000,
        });
      } finally {
        this.sendingQuote = false;
      }
    },
  },

  async mounted() {
    await this.getQuote(this.$route.params.id);
    await this.getCustomerDetails();
  },
  watch: {
    //
  },
};
</script>

<style lang="scss" scoped>
.page__title {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 39px;
}

.pContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__card {
    background-color: #fff;
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 3%),
      0px 6px 10px 0px rgb(0 0 0 / 3%), 0px 1px 18px 0px rgb(0 0 0 / 3%);
    width: 100%;
    height: auto;
    border: transparent;
    border-radius: 4px;

    .cardTitle {
      font-family: "inter";
      font-size: 20px;
      font-weight: 700;
      color: var(--v-primary-base);
    }
  }
}

.linkWrapper {
  cursor: pointer;
  background-color: #f2f2f2;
  border-radius: 4px;
  border: transparent;
}

.headerBtnTxt {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #19283d;
  text-decoration: underline;
  text-transform: capitalize;
}

@media only screen and (min-width: 600px) {
  .page__title {
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 36px;
  }
}

@media only screen and (min-width: 768px) {
  /* For desktop: */
  .page__title {
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 39px;
  }
}
</style>
